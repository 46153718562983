<template>
  <div
    class="flix-text-danger flix-html-strong flix-html-small"
    v-if="checkDate()"
  >
    {{ $t("dates.error.end") }}
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    checkDate() {
      var endDate = JSON.parse(
        JSON.stringify(this.$store.state.business.unsaved)
      ).end;
      var startDate = JSON.parse(
        JSON.stringify(this.$store.state.business.unsaved)
      ).start;

      if (typeof endDate === "undefined" || !endDate) {
        return false;
      }

      if (typeof startDate === "undefined" || !startDate) {
        return false;
      }

      endDate = endDate.split(" ");
      endDate[0] = endDate[0].split("-");
      endDate[1] = endDate[1].split(":");
      startDate = startDate.split(" ");
      startDate[0] = startDate[0].split("-");
      startDate[1] = startDate[1].split(":");

      var end = new Date();
      end.setYear(endDate[0][0]);
      end.setMonth(endDate[0][1] - 1);
      end.setDate(endDate[0][2]);
      end.setHours(endDate[1][0]);
      end.setMinutes(endDate[1][1]);
      end.setSeconds(0);
      end.setMilliseconds(0);

      var start = new Date();
      start.setYear(startDate[0][0]);
      start.setMonth(startDate[0][1] - 1);
      start.setDate(startDate[0][2]);
      start.setHours(startDate[1][0]);
      start.setMinutes(startDate[1][1]);
      start.setSeconds(0);
      start.setMilliseconds(0);

      if (start.getTime() > end.getTime()) {
        return true;
      }

      return false;
    }
  }
};
</script>
<style lang="sass" scoped></style>
